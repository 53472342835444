<template>
  <!-- 广告投放 -->
  <div
    class="storage"
    v-loading.fullscreen.lock="loading"
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <div class="append">
      <div class="seach">
        <el-select placeholder="请选择店铺" v-model="pageInfo.profileId">
          <el-option-group
            v-for="group in groupLists"
            :key="group.id"
            :label="group.name"
          >
            <el-option
              v-for="item in group.marketPlaceTree"
              :key="item.profileId"
              :label="group.name + '-' + item.name"
              :value="item.profileId"
              @click.native="
                checkGroup(group.id, item.id, item.profileId, item.currency)
              "
              >{{ item.name }}
            </el-option>
          </el-option-group>
        </el-select>
        <TimeQuantum
          style="margin-right: 10px"
          @selectFinish="topTime"
          section="近7天"
          @sectionFinish="sectionSure"
        ></TimeQuantum>
        <el-select
          placeholder="请输入后/直接选择组合"
          clearable
          filterable
          v-model="pageInfo.adCombination"
          @change="find"
        >
          <el-option
            v-for="item in portfoliosList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            @click.native="change_event(item.id)"
          >
            {{ item.value }}
          </el-option>
        </el-select>
        <el-select
          placeholder="请输入后/直接选择广告活动"
          clearable
          filterable
          v-model="pageInfo.adActivity"
          @change="find"
        >
          <el-option
            v-for="item in adVertisCampaignList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            @click.native="handle_event(item.adCombination, item.id)"
          >
            <p>
              <span>({{ item.type }})</span>
              <span
                v-if="item.state == 'enabled'"
                style="color: #1afa29"
                class="iconfont icon-duihao"
              ></span>
              <span
                v-if="item.state == 'paused'"
                style="font-size: 14px; color: red"
                class="iconfont icon-zantingtingzhi"
              ></span>
              <span
                v-if="item.state == 'archived'"
                style="font-size: 14px; color: #dcdfe6"
                class="iconfont icon-guidang"
              ></span>
              <span>{{ item.value }}</span>
            </p>
          </el-option>
        </el-select>
        <el-select
          placeholder="请输入后/直接选择组"
          clearable
          filterable
          v-model="pageInfo.adGroup"
          @change="find"
        >
          <el-option
            v-for="item in adCollectionList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
            <p>
              <span>({{ item.type }})</span>
              <span
                v-if="item.state == 'enabled'"
                style="color: #1afa29"
                class="iconfont icon-duihao"
              ></span>
              <span
                v-if="item.state == 'paused'"
                style="font-size: 14px; color: red"
                class="iconfont icon-zantingtingzhi"
              ></span>
              <span
                v-if="item.state == 'archived'"
                style="font-size: 14px; color: #dcdfe6"
                class="iconfont icon-guidang"
              ></span>
              <span>{{ item.value }}</span>
              <span>{{ item.value }}</span>
            </p>
          </el-option>
        </el-select>
        <el-select
          placeholder="所有投放状态"
          v-model="pageInfo.targetState"
          @change="find"
        >
          <el-option
            v-for="item in targetStateList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          placeholder="所有广告活动状态"
          v-model="pageInfo.adActiveState"
          @change="find"
        >
          <el-option
            v-for="item in campaignStateList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          placeholder="所有投放"
          v-model="pageInfo.allPutOn"
          @change="find"
        >
          <el-option
            v-for="item in targetTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-model="pageInfo.isFavorite"
          style="width: 150px; margin-right: 10px"
          filterable
          placeholder="关注状态"
          @change="find"
        >
          <el-option
            v-for="item in favoriteTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入ASIN或者类目名称"
          v-model="pageInfo.categoryName"
          @keyup.enter.native="find"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="find"
          >立即查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-setting"
          @click="handleManageGoods"
          >管理商品定位</el-button
        >
        <el-dropdown style="margin-left: 20px" @command="handleCommand">
          <el-button icon="el-icon-warning" type="primary">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">调整竞价</el-dropdown-item>
            <el-dropdown-item command="2">应用建议竞价</el-dropdown-item>
            <el-dropdown-item command="3">置为暂停</el-dropdown-item>
            <el-dropdown-item command="4">恢复启动</el-dropdown-item>
            <el-dropdown-item command="5">置为归档</el-dropdown-item>
            <el-dropdown-item command="6">同步状态/竞价</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 列表区域 -->
        <el-table
          border
          :data="tableData"
          @sort-change="sortTable"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          header-cell-style="background:#FAFAFA;text-align:center;font-size:14px"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            width="350px"
            label="投放方式"
            align="center"
            prop="name"
            fixed
          >
            <template slot-scope="scope">
              <p style="text-align: left; display: flex; align-items: center">
                <span
                  @click="
                    focusChange(scope.row.id, scope.row.focusOn, scope.$index)
                  "
                  :class="
                    scope.row.focusOn == 0
                      ? 'el-icon-star-off'
                      : 'el-icon-star-on'
                  "
                  style="font-size: 17px; margin-right: 10px"
                ></span>
                <span>{{ scope.row.name }}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="启停/分析"
            prop=""
            align="center"
            width="120"
            fixed
          >
            <template slot-scope="scoped">
              <div
                style="
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  padding: 0 20px;
                "
              >
                <el-tooltip effect="dark" content="已归档" placement="top">
                  <span
                    v-if="scoped.row.state == 'archived'"
                    class="el-icon-document"
                  ></span>
                </el-tooltip>
                <el-switch
                  v-if="scoped.row.state != 'archived'"
                  v-model="scoped.row.state"
                  active-color="#13ce66"
                  inactive-color="#C1C1C1"
                  active-value="enabled"
                  inactive-value="paused"
                  @change="changeStatus(scoped.row.targetId, scoped.row.state)"
                ></el-switch>
                <el-tooltip
                  effect="dark"
                  content="查看历史趋势"
                  placement="top"
                >
                  <el-link
                    class="iconfont icon-jingyingfenxi"
                    style="font-size: 22px"
                    @click="getChart(scoped.row)"
                  ></el-link>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="广告组"
            align="center"
            prop="adGroupName"
            fixed
          ></el-table-column>
          <el-table-column
            label="广告活动"
            align="center"
            prop="campaignName"
          ></el-table-column>
          <el-table-column
            label="活动状态"
            align="center"
            prop="campaignState"
          >
          <template slot-scope="scope">
               <span v-if="scope.row.campaignState=='已启用'" style="color: #3CB371">{{ scope.row.campaignState }}</span>
               <span v-if="scope.row.campaignState=='已暂停'" style="color: #B22222">{{ scope.row.campaignState }}</span>
               <span v-if="scope.row.campaignState=='已归档'" style="color: #808080">{{ scope.row.campaignState }}</span>
             </template>
          </el-table-column>
          <el-table-column label="定时策略" align="center" width="120" prop="expressionType">
            <template slot="header">
              <span>定时策略</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 420px"
                >
                  <p>广告对象定时策略绑定情况。</p>
                  <p>
                    悬停策略类型名称上，可以查看策略的名称、状态、生效状态和应用策略状态。
                  </p>
                  <p>
                    点击策略类型名称，可以查看策略的详情、启停应用状态和修改分时计算基准值。
                  </p>
                  <p>
                    策略类型名称如果是<span style="color: #bf8350"
                      >灰色字体，表示策略当前处于不被执行状态</span
                    >（策略未生效、策略被暂停、对象暂停应用了策略）。
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120">
            <template slot="header">
              <span>建议竞价</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 420px"
                >
                  <p>
                    建议竞价是为您量身定制的，有助于您的关键词赢得展示次数。
                  </p>
                  <p>
                    建议竞价范围基于与您的广告类似且在过去 7
                    天内赢得了展示次数的广告的竞价。
                    <a
                      style="color: #13ce66"
                      target="top"
                      href="https://advertising.amazon.com/help/ref=ams_head_help?entityId=ENTITY3VAZBWP88M62S#GYQY2B3R58ZHSHJJ"
                      >了解更多信息</a
                    >
                  </p>
                  <br />
                  <p>
                    自动投放未归档的广告组、未归档的关键词和商品定位可以获取建议竞价。
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-link
                type="primary"
                v-if="
                  scope.row.suggested == undefinded &&
                  scope.row.state != 'archived'
                "
                @click="onObtainBtn(scope.$index, scope.row.targetId)"
                >获取</el-link
              >
              <div
                v-else-if="scope.row.suggested != undefinded"
                style="font-size: 14px; text-align: left"
              >
                <p>{{ currency }}{{ scope.row.suggested }}</p>
                <p>
                  {{ currency }}{{ scope.row.rangeStart }}--{{ currency
                  }}{{ scope.row.rangeEnd }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="bid"
            sortable="custom"
            width="100"
          >
            <template slot="header">
              <span>竞价</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 350px"
                >
                  <p>
                    当关键词触发您的广告时，您需要为点击支付的金额。
                    <a
                      style="color: #13ce66"
                      href="https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GTX8JYBTJX5EUCZW"
                      target="top"
                      >了解更多信息</a
                    >
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-tooltip
                effect="dark"
                content="点击可调整竞价"
                placement="top"
              >
                <el-link
                  type="primary"
                  @click="onClickBid(scope.row, scope.$index)"
                  >{{ currency }}{{ scope.row.bid }}</el-link
                >
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="曝光量"
            align="center"
            prop="impressions"
            sortable="custom"
            width="120"
          >
            <template slot="header">
              <span>曝光量</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <p>广告投放给买家的展示总数。</p>
                  <br />
                  <p>
                    没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="点击量"
            align="center"
            prop="clicks"
            width="120"
            sortable="custom"
          >
            <template slot="header">
              <span>点击量</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 350px"
                >
                  <p>点击量：广告被点击的总次数。</p>
                  <p>点击率：每一次曝光被点击的概率。</p>
                  <br />
                  <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p>
                  <br />
                  <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                  <p>
                    点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。
                  </p>
                  <p>
                    点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="点击率"
            align="center"
            width="120"
            prop="clickRate"
            sortable="custom"
          >
            <template slot="header">
              <span>点击率</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 350px"
                >
                  <p>点击量：广告被点击的总次数。</p>
                  <p>点击率：每一次曝光被点击的概率。</p>
                  <br />
                  <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p>
                  <br />
                  <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                  <p>
                    点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。
                  </p>
                  <p>
                    点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="花费"
            align="center"
            width="120"
            prop="cost"
            sortable="custom"
          >
            <template slot="header">
              <span>花费</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 350px"
                >
                  <p>广告被点击，所产生的总费用，相当于您的广告总成本 。</p>
                  <br />
                  <p>
                    1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；
                  </p>
                  <br />
                  <p>
                    2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；
                  </p>
                  <p>3、计算规则：广告花费 = CPC竞价 * 被点击次数；</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="CPC均价"
            align="center"
            prop="clickAveCost"
            width="120"
            sortable="custom"
          >
            <template slot="header">
              <span>CPC均价</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 350px"
                >
                  <p>CPC点击的平均价格。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="订单数"
            align="center"
            width="120"
            prop="attributedConversions14d"
            sortable="custom"
          >
            <template slot="header">
              <span>订单数</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 350px"
                >
                  <p>通过广告带来的订单量。</p>
                  <br />
                  <p>
                    1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；
                  </p>
                  <p>
                    2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="CVR"
            align="center"
            width="120"
            prop="cvr"
            sortable="custom"
          >
            <template slot="header">
              <span>CVR</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 300px"
                >
                  <p>每一次点击带来订单的概率。</p>
                  <br />
                  <p>计算规则：CVR = 订单数 / 点击量 * 100%；</p>
                  <p>例如：CVR为10%，每100次点击，能带来10笔广告订单。</p>
                  <br />
                  <p>亚马逊广告，CVR一般为5-10%左右，</p>
                  <p>
                    CVR的重要影响因素：商品图片、卖点、评论内容、促销活动，如果CVR低，建议您优化listing，根据商品的受众人群，提炼卖点、设计精美的场景图、善用Q&A，Review等。
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="CPA"
            align="center"
            prop="cpa"
            width="120"
            sortable="custom"
          >
            <template slot="header">
              <span>CPA</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 300px"
                >
                  <p>每一笔广告订单平均所需花费用</p>
                  <br />
                  <p>计算规则：CPA = 花费 / 订单数；</p>
                  <br />
                  <p>商品的新品期，CPA相对较高，</p>
                  <p>商品的稳定期，CPA逐渐降低。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="销售额"
            align="center"
            prop="attributedSales14d"
            sortable="custom"
            width="120"
          >
            <template slot="header">
              <span>销售额</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 300px"
                >
                  <p>通过广告带来的销售额。</p>
                  <br />
                  <p>
                    1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；
                  </p>
                  <p>
                    2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="ACoS"
            align="center"
            width="120"
            prop="acos"
            sortable="custom"
          >
            <template slot="header">
              <span>ACoS</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 300px"
                >
                  <p>广告销售成本率，广告花费占广告销售额的比例。</p>
                  <br />
                  <p>计算规则：ACoS = 广告花费 / 广告销售额 * 100%；</p>
                  <p>
                    例如，ACoS为90%，100美元的销售额，需要支付90%（90元）广告费。
                  </p>
                  <p>ACoS是衡量广告效果的重要指标，一般来说越低越好，</p>
                  <p>25%是业内普遍的衡量标准，</p>
                  <p>但并不能用ACoS指标单一来衡量广告的效果。</p>
                  <br />
                  <p>
                    在不同的细分市场、商品周期、用户周期，品牌定位，都影响着ACoS的设置目标，
                  </p>
                  <p>
                    例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品，
                  </p>
                  <p>通过广告一次获客，客户可带来长期的购买回报，</p>
                  <p>因此这类商品ACoS高，反而有益于创造更多利润。</p>
                  <br />
                  <p>
                    拓展阅读<a
                      href="https://www.baidu.com/s?ie=UTF-8&wd=ACoS%E8%B6%8A%E4%BD%8E%E8%B6%8A%E5%A5%BD%E5%90%97%EF%BC%9F&tn=62095104_41_oem_dg"
                      style="color: #13ce66"
                      >ACoS越低越好吗？</a
                    >
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="ROAS"
            width="120"
            align="center"
            prop="roas"
            sortable="custom"
          >
            <template slot="header">
              <span>ROAS</span>
              <el-tooltip effect="dark" placement="top">
                <div
                  slot="content"
                  style="line-height: 20px; min-width: 150px; max-width: 300px"
                >
                  <p>广告投入回报比，广告销售额与成本的倍数。</p>
                  <br />
                  <p>计算规则：RoAS = 销售额 / 花费；</p>
                  <p>
                    例如，RoAS为2，说明花10美元做广告，带来了20美元的销售额。
                  </p>
                  <br />
                  <p>
                    RoAS是衡量广告效果投入成本的效率指标，一般情况下，越大越好，亚马逊上的平均RoAS约为3，消费类电子产品的RoAS约为9，玩具和游戏的RoAS约为4.5，
                  </p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          :page-size="pageInfo.pageSize"
          :current-page="pageInfo.current"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 管理商品定位 -->
    <el-dialog
      width="1100px"
      title="管理商品定位"
      :visible.sync="manageGoodsVisible"
    >
      <el-form
        :label-position="labelPosition"
        :model="formLabelAlign"
        label-width="80px"
      >
        <el-form-item label="广告活动">
          <el-select
            style="width: 620px"
            placeholder="请选择广告活动"
            v-model="addInfo.campaignId"
          >
            <!-- v-model="formLabelAlign.adActivity" -->
            <el-option
              v-for="item in adCampaignData"
              :key="item.id"
              :label=" item.value"
              :value="item.id"
              @click.native="changeItem(item)"
            >
              <template>
                <span>({{ item.type }})</span>-
                <span
                  v-if="item.state == 'enabled'"
                  style="color: #1afa29"
                  class="iconfont icon-duihao"
                ></span>
                <span
                  v-if="item.state == 'paused'"
                  style="font-size: 14px; color: red"
                  class="iconfont icon-zantingtingzhi"
                ></span>
                <span
                  v-if="item.state == 'archived'"
                  style="font-size: 14px; color: #dcdfe6"
                  class="iconfont icon-guidang"
                ></span>
                <span>{{ item.value }}</span>
              </template>
            </el-option>
          </el-select>
          <span v-if="type == 1" @click="clickBtn(type)" class="activityInfor"
            >活动信息 (查看)</span
          >
          <span v-if="type == 2" @click="clickBtn(type)" class="activityInfor"
            >活动信息 (隐藏)</span
          >
        </el-form-item>
        <!-- 显示与隐藏 -->
        <div class="show_content" v-show="show_cont">
          <div class="look">
            <div class="fn">
              <div class="lable">广告组合</div>
              <div class="value">
                {{ detailsObject.type }}/{{ detailsObject.value }}
              </div>
            </div>
            <div class="fn">
              <div class="lable">活动起止日期</div>
              <div class="value">{{ detailsObject.date }}</div>
            </div>
            <div class="fn">
              <div class="lable">每日预算({{currency}})</div>
              <div class="value">{{ detailsObject.bid }}</div>
            </div>
            <div class="fn">
              <div class="lable">定向策略</div>
              <div class="value">{{ detailsObject.targetingType }}</div>
            </div>
            <div class="fn">
              <div class="lable">活动竞价策略</div>
              <div class="value">{{ detailsObject.target }}</div>
            </div>
            <div class="fn">
              <div class="lable">商品页面</div>
              <div class="value">{{ detailsObject.page }}</div>
            </div>
            <div class="fn">
              <div class="lable">搜索结果顶部</div>
              <div class="value">{{ detailsObject.top }}</div>
            </div>
          </div>
        </div>
        <el-form-item label="广告组">
          <el-select
            style="width: 620px"
            placeholder="请选择广告组"
            v-model="addInfo.adGroupId"
          >
            <!-- v-for="item in arrLists" -->

            <el-option
              v-for="item in addInfo.screenGroupsList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
              :disabled="item.type == '词' ? true : false"
              @click.native="getExistGoods(item.id, item.type)"
            >
              <!-- ({{ item.type }})--{{ item.targetType }}--{{ item.value }} -->
              <template>
                <span>({{ item.type }}) </span>
                <span
                  v-if="item.state == 'enabled'"
                  style="color: #1afa29"
                  class="iconfont icon-duihao"
                ></span>
                <span
                  v-if="item.state == 'paused'"
                  style="font-size: 14px; color: red"
                  class="iconfont icon-zantingtingzhi"
                ></span>
                <span
                  v-if="item.state == 'archived'"
                  style="font-size: 14px; color: #dcdfe6"
                  class="iconfont icon-guidang"
                ></span>
                <span> {{ item.value }}</span>
              </template>
            </el-option>
          </el-select>
          <span
            v-if="data == 1"
            @click="handleClick(data)"
            class="activityInfor"
            >广告组信息 (查看)</span
          >
          <span
            v-if="data == 2"
            @click="handleClick(data)"
            class="activityInfor"
            >广告组信息 (隐藏)</span
          >
        </el-form-item>
        <!-- 显示与隐藏 -->
        <div class="class_div" v-show="show_adGroup">
          <el-row type="flex" justify="flex-start" style="margin-top: 20px">
            <div class="lable_div" style="margin-right: 120px">
              定向策略： {{ arrObject.targetType }}
            </div>
            <div class="lable_div" style="margin-right: 120px">
              默认竞价：{{ arrObject.default }}
            </div>
          </el-row>
        </div>
        <!-- 中间区域 -->
        <div v-show="show_mainContent">
          <div class="nav_top">
            <el-row type="flex" justify="flex-start">
              <div class="el_row_top">
                <h4 style="margin-right: 5px">商品</h4>
                <el-tooltip placement="top">
                  <div slot="content">字段解释Tips, 即将上线。<br /></div>
                  <i
                    class="el-icon-question"
                    style="cursor: pointer; margin-right: 10px"
                  ></i>
                </el-tooltip>
              </div>
              <el-select
                placeholder="商品状态"
                v-model="formList.status"
                style="margin-right: 20px"
              >
                <el-option
                  v-for="item in goodsStatusList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-input
                style="width: 360px; margin-right: 20px"
                v-model="searchByAsin"
                placeholder="请输入ASIN"
              >
                <el-select
                  clearable
                  @clear="clearAsin"
                  slot="prepend"
                  v-model="select_Asin"
                  placeholder="选择输入类型"
                  style="width: 130px"
                >
                  <el-option
                    v-for="item in subASINList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-input>
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="asyncSearch"
                >立即查询</el-button
              >
            </el-row>
          </div>
          <!-- 中间区域(1) -->
          <div class="el_nav_middle_div">
            <!-- 左侧卡片 -->
            <div class="Box">
              <div>
                <el-tabs
                  v-model="CurSelecteName"
                  @tab-click="handle_Click_first"
                >
                  <el-tab-pane label="搜索" name="first"></el-tab-pane>
                  <el-tab-pane label="输入列表" name="second">
                    <span slot="label">
                      输入列表
                      <el-radio-group
                        v-model="radioState"
                        v-show="radioState_flag"
                      >
                        <el-radio label="asin">子ASIN</el-radio>
                        <el-radio label="parent_asin">父ASIN</el-radio>
                      </el-radio-group>
                    </span>
                  </el-tab-pane>
                </el-tabs>
                <el-button type="primary" class="all" @click="allInt('add')"
                  >添加全部</el-button
                >
                <div v-if="CurSelecteName == 'first'">
                  <div style="height: 480px; overflow: auto">
                    <div class="item" v-for="item in goodsList" :key="item.id">
                      <div class="pic">
                        <img :src="item.imageUrl" alt="" width="100%" />
                      </div>
                      <div class="SKU">
                        <p>
                          <span class="state"
                            ><img src="" alt="" width="100%" /></span
                          ><span>子 {{ item.asin }}</span>
                        </p>
                        <p>
                          <span>SKU:{{ item.commoditySku }}</span>
                        </p>
                      </div>
                      <div class="realizable">
                        <p>可售库存：{{ item.total }}</p>
                        <p>可售天数：{{ item.canSellDay }}</p>
                      </div>
                      <div class="price">
                        <p>售价：{{currency}}{{ item.sellPrice }}</p>
                      </div>
                      <div
                        style="
                          font-weight: blod;
                          font-size: 23px;
                          color: #409eff;
                          width: 30px;
                        "
                      >
                        <span
                          @click="toAdded(item)"
                          v-if="item.operation == 0"
                          class="el-icon-circle-plus-outline"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <!-- 分页区域 -->
                  <el-pagination
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                    :current-page="formList.current"
                    :page-sizes="[10, 30, 50]"
                    :page-size="formList.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="goodsTotal"
                    style="margin-top: 10px; text-align: cenetr"
                  >
                  </el-pagination>
                </div>

                <div v-if="CurSelecteName == 'second'">
                  <el-input
                    v-model="textField"
                    type="textarea"
                    rows="18"
                    placeholder="手动输入ASIN, 多个时换行输入 如 
 B077ZKF9ZN 
 B07X1QPRYQ 
 B07V2CLJLV"
                    style="margin-top: 20px"
                  ></el-input>
                  <el-button
                    style="float: right; margin-top: 20px"
                    type="primary"
                    icon="el-icon-circle-plus"
                    @click="handle_Add"
                    >添加</el-button
                  >
                </div>
              </div>

              <div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane
                    :label="'已添加' + '(' + rightData.length + ')'"
                    name="first"
                  ></el-tab-pane>
                </el-tabs>
                <el-button type="primary" class="all" @click="allInt('del')"
                  >删除全部</el-button
                >
                <div style="height: 480px; overflow: auto">
                  <template v-for="(item, index) in rightData">
                    <div class="item" :key="item.id">
                      <div class="pic">
                        <img :src="item.imageUrl" alt="" width="100%" height="100%"/>
                      </div>
                      <div class="SKU">
                        <p>
                          <span class="state"
                            ><img src="" alt="" width="100%" /></span
                          ><span>子 {{ item.asin }}</span>
                        </p>
                        <p>
                          <span>SKU:{{ item.commoditySku }}</span>
                        </p>
                      </div>
                      <div class="realizable">
                        <p>可售库存：{{ item.total }}</p>
                        <p>可售天数：{{ item.canSellDay }}</p>
                      </div>
                      <div class="price">
                        <p>售价：{{currency}}{{ item.sellPrice }}</p>
                      </div>
                      <div
                        v-if="item.yet != 1"
                        style="
                          font-weight: blod;
                          font-size: 23px;
                          color: #409eff;
                          width: 30px;
                        "
                      >
                        <span
                          @click="toGoods(item, index)"
                          class="el-icon-delete"
                        ></span>
                      </div>
                      <div
                        v-if="item.yet == 1"
                        style="color: #999999; font-size: 14px; width: 60px"
                      >
                        已存在
                      </div>
                    </div>
                  </template>
                </div>
                <div style="text-align: right; padding-top: 20px">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="10"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
          <!-- 中间区域(2) -->
          <!-- <div>
            <el-card class="box-card_middle">
              <div slot="header" class="clearfix">
                <el-tabs v-model="CurSelecteItem">
                
                  <el-tab-pane name="first">
                    <span slot="label">
                      分类
                      <el-tooltip placement="top">
                        <div slot="content">字段解释Tips, 即将上线。<br /></div>
                        <i
                          class="el-icon-question"
                          style="cursor: pointer; margin-right: 10px"
                        ></i>
                      </el-tooltip>
                    </span>
                    <el-tabs v-model="active_Item">
                      <el-tab-pane label="建议" name="advice">
                        <div style="height: 450px; overflow-y: scroll">
                          <div
                            class="item"
                            v-for="item in left_Tdata"
                            :key="item.id"
                          >
                            <div class="realizables">
                              <p style="color: #b7b9bd">{{ item.path }}</p>
                              <p>分类：{{ item.name }}</p>
                            </div>
                            <div
                              style="
                                font-weight: blod;
                                font-size: 23px;
                                color: #409eff;
                                width: 30px;
                                margin-left: 35%;
                              "
                            >
                              <span
                                @click="toAdd(item)"
                                v-show="item.operations == 0"
                                class="el-icon-circle-plus-outline"
                              ></span>
                            </div>
                            <el-button
                              type="text"
                              style="float: left"
                              @click="getGroupDetailInit()"
                              >细化</el-button
                            >
                            <el-dialog
                              width="40%"
                              :visible.sync="elaborationBox"
                              append-to-body
                            >
                              <template slot="title">
                                <p>细化产品分类：&nbsp;</p>
                                <p
                                  style="
                                    color: #999999;
                                    font-size: 14px;
                                    margin-top: 10px;
                                    border-bottom: 1px #d9d9d9 solid;
                                    padding-bottom: 10px;
                                  "
                                >
                                  根据特定品牌、价格范围、星级和Prime配送资格，细化分类
                                </p>
                              </template>
                              <div style="margin-top: -20px; padding: 0 20px">
                                <p>品牌</p>
                                <el-select
                                  style="width: 100%; margin: 10px 0"
                                  placeholder="请输入或选择品牌"
                                  v-model="value"
                                  multiple
                                  filterable
                                  allow-create
                                  default-first-option
                                >
                                  <el-option
                                    v-for="item in brandList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                  >
                                  </el-option>
                                </el-select>
                                <p>商品价格范围</p>
                                <el-input
                                  placeholder="无最低商品价格"
                                  el-input
                                  style="width: 180px; margin: 15px 0"
                                >
                                  <el-button slot="append">¥</el-button>
                                </el-input>
                                -
                                <el-input
                                  placeholder="无最高商品价格"
                                  style="width: 180px; margin: 10px 0"
                                >
                                  <el-button slot="append">¥</el-button>
                                </el-input>
                                <p>查看星级评定 0 星 ~ 5 星</p>
                                <div
                                  class="block"
                                  style="margin: 15px 0 30px 0"
                                >
                                  <el-slider
                                    v-model="mark"
                                    range
                                    :marks="marks"
                                    show-stops
                                    :max="5"
                                  >
                                  </el-slider>
                                </div>
                                <p style="margin: 20px 0">配送</p>
                                <el-radio
                                  v-model="radio"
                                  :label="item.id"
                                  v-for="item in primeTypeList"
                                  :key="item.id"
                                  >{{ item.value }}</el-radio
                                >
                              </div>
                              <span slot="footer" class="dialog-footer">
                                <el-button @click="dialogVisible = false"
                                  >取 消</el-button
                                >
                                <el-button
                                  type="primary"
                                  @click="dialogVisible = false"
                                  >确 定</el-button
                                >
                              </span>
                            </el-dialog>
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </el-tab-pane>
               
                  <el-tab-pane label="各个商品" name="second">
                    <span slot="label">
                      各个商品
                      <el-tooltip placement="top">
                        <div slot="content">字段解释Tips, 即将上线。<br /></div>
                        <i
                          class="el-icon-question"
                          style="cursor: pointer; margin-right: 10px"
                        ></i>
                      </el-tooltip>
                    </span>
                    <el-tabs v-model="active_current">
                      <el-tab-pane label="已建议" name="hasBeenSuggest">
                        <div style="height: 480px; overflow: auto">
                          <div
                            class="item"
                            v-for="item in leftdata"
                            :key="item.id"
                          >
                            <div class="realizable">
                              <p>{{ item.recommendedTargetAsin }}</p>
                            </div>
                            <div
                              style="
                                font-weight: blod;
                                font-size: 23px;
                                color: #409eff;
                                width: 30px;
                              "
                            >
                              <span
                                @click="toAdd(item)"
                                v-show="item.operations == 0"
                                class="el-icon-circle-plus-outline"
                              ></span>
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="手动输入" name="manuallyEnter">
                        <el-input
                          :rows="10"
                          type="textarea"
                          v-model="manually_Enter"
                          placeholder="手动输入ASIN, 多个时换行输入"
                          style="margin-top: 20px"
                        ></el-input>
                        <el-divider content-position="left"></el-divider>
                        <el-button
                          @click="async_add"
                          style="float: right; color: #000000"
                          icon="el-icon-circle-plus"
                          >添加</el-button
                        >
                      </el-tab-pane>
                    </el-tabs>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-card>
          </div>
          <div>
            <el-card class="box-card_bottom">
              <div slot="header" class="clearfix">
                <span>定位商品数{{ this.listGet.length }}个</span>
                <el-button
                  style="
                    float: right;
                    padding: 3px 0;
                    background: none;
                    border: none;
                  "
                  type="text"
                  @click="handle_All_Remove"
                  >全部删除</el-button
                >
                <div style="margin-top: 50px">
                  <div style="display: inline-block">分类和商品</div>
                  <div style="display: inline-block; padding-left: 67%">
                    竞价
                  </div>
                  <div
                    style="
                      display: inline-block;
                      float: right;
                      margin-right: 35px;
                    "
                  >
                    删除
                  </div>
                </div>

                <div style="height: 480px; overflow: auto; margin-top: 10px">
                  <div class="item" v-for="item in listGet" :key="item.id">
                    <div class="rea">
                      <div
                        class="a"
                        style="display: inline-block; width: 754px"
                      >
                        {{ item.value }}
                      </div>
                      <div class="b" style="display: inline-block">
                        ￥{{ item.bid }}
                      </div>
                    </div>

                    <div
                      v-if="item.yet != 1"
                      style="
                        font-weight: blod;
                        font-size: 23px;
                        color: #409eff;
                        width: 30px;
                        margin: 0 auto;
                      "
                    >
                      <span @click="toGood(item)" class="el-icon-delete"></span>
                    </div>
                    <div
                      v-if="item.yet == 1"
                      style="color: #999999; font-size: 14px; width: 50px"
                    >
                      已存在
                    </div>
                  </div>
                </div>
              </div>
              <div class="el-async_goods">
                <el-button
                  style="float: right; padding: 10px 15px; color: white"
                  type="primary"
                  @click="async_add_goods"
                  >确定新增商品定位</el-button
                >
              </div>
            </el-card>
          </div> -->

          <!-- 商品投放 -->

          <div
            class="goodsPut"
            v-if="putStatus == '商'"
            style="margin-bottom: 20px"
          >
            <el-tabs
              v-model="putType"
              style="height: 20px"
              type="card"
              @tab-click="handleClicks"
            >
              <el-tab-pane label="分类" name="asinCategorySameAs">
                <span slot="label"
                  >分类
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 250px; line-height: 19px">
                      <p>各类商品是按相似性划分在一起的商品，如女士跑鞋。</p>
                    </div>
                    <i class="iconfont icon-wenhao"></i>
                  </el-tooltip>
                </span>
              </el-tab-pane>
              <el-tab-pane label="各个商品" name="asinSameAs">
                <span slot="label"
                  >各个商品
                  <el-tooltip placement="top">
                    <div slot="content" style="width: 250px; line-height: 19px">
                      <p>定位到与您广告中宣传的商品类似的各个商品。</p>
                    </div>
                    <i class="iconfont icon-wenhao"></i>
                  </el-tooltip>
                </span>
              </el-tab-pane>
            </el-tabs>
            <el-tabs
              v-model="putTypeInside"
              style="height: 30px; margin: 10px"
              @tab-click="this.inputASIN = ''"
            >
              <el-tab-pane
                v-if="putType == 'asinCategorySameAs'"
                label="建议"
                name="first"
              ></el-tab-pane>
              <el-tab-pane
                v-if="putType == 'asinSameAs'"
                label="已建议"
                name="second"
              ></el-tab-pane>
              <el-tab-pane
                v-if="putType == 'asinSameAs'"
                label="手动输入"
                name="third"
              ></el-tab-pane>
            </el-tabs>
            <el-table
              v-loading="suggestLoading"
              v-if="putTypeInside == 'first'"
              :show-header="false"
              :data="left_Tdata"
              style="width: 100%; height: 500px"
            >
              <el-table-column prop="prop" label="label" width="width">
                <template slot-scope="scope">
                  <p style="color: #999999; font-size: 15px">
                    {{ scope.row.path }}
                  </p>
                  <p style="font-size: 15px">分类：{{ scope.row.name }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="prop" label="label" width="33">
                <template slot-scope="scope">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                  >
                    <span
                      v-show="scope.row.operation == 0"
                      @click="addLocationGoods(scope.row)"
                      style="font-size: 23px; color: #409eff"
                      class="el-icon-circle-plus-outline"
                    ></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                prop="prop"
                label="label"
                width="60px"
              >
                <template slot-scope="scope"
                  ><el-link
                    style="color: #999999; line-height: 1"
                    @click="getGroupDetailInit(scope.row.id, scope.row)"
                    >细化</el-link
                  ></template
                >
              </el-table-column>
            </el-table>
            <el-table
              v-loading="suggestedLoading"
              height="400px"
              v-if="putTypeInside == 'second'"
              :data="targetProductList"
              style="width: 100%"
              header-cell-style="background:#DFEFEFE;font-size:14px"
            >
              <el-table-column
                prop="recommendedTargetAsin"
                label="ASIN"
                width="width"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="prop"
                label="添加"
                width="70"
              >
                <template slot-scope="scope">
                  <span
                    @click="addLocationGoods(scope.row)"
                    v-if="scope.row.operation == 0"
                    style="font-size: 23px; color: #409eff"
                    class="el-icon-circle-plus-outline"
                  ></span>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="putTypeInside == 'third'">
              <el-input
                v-model="inputASIN"
                style="margin-top: 10px"
                rows="10"
                cols="43"
                type="textarea"
                placeholder="手动输入ASIN,多个时换行输入 如 
B077ZKF9ZN 
B07X1QPRYQ 
B07V2CLJLV"
              >
              </el-input>
              <el-button
                style="float: right; margin: 10px 0"
                @click="addLocationGoods"
                >添加</el-button
              >
            </div>
            <p style="line-height: 1; text-align: center; margin-top: 60px">
              定位商品数<el-button
                type="text"
                style="float: right; margin-bottom: 5px"
                @click="removeAll('all')"
                >全部删除</el-button
              >
            </p>
            <el-table
              :data="addInfo.targetAddReqList"
              style="width: 100%; height: 500px"
              header-cell-style="background:#FAFAFA;font-size:14px"
            >
              <el-table-column prop="prop" label="分类和商品" width="width">
                <template slot-scope="scope" style="font-size: 14px">
                  <div v-if="scope.row.yet != 1">
                    <p
                      v-if="
                        scope.row.amzAdTargetExpressionList[0].type ==
                        'asinCategorySameAs'
                      "
                      style="color: #999999; font-size: 15px"
                    >
                      {{ scope.row.path }}
                    </p>
                    <p
                      v-if="
                        scope.row.amzAdTargetExpressionList[0].type ==
                        'asinCategorySameAs'
                      "
                      style="font-size: 15px"
                    >
                      <span
                        >分类：{{
                          scope.row.amzAdTargetExpressionList[0].name
                        }}</span
                      >&ensp;&ensp;
                      <span v-if="scope.row.name != undefined"
                        >品牌：{{ scope.row.name }}</span
                      >&ensp;&ensp;
                      <span
                        v-if="
                          scope.row.asinPriceGreaterThan != undefined &&
                          scope.row.asinPriceGreaterThan != '' &&
                          scope.row.asinPriceLessThan != undefined &&
                          scope.row.asinPriceLessThan != ''
                        "
                        >价格区间：{{
                          scope.row.asinPriceGreaterThan +
                          " - " +
                          scope.row.asinPriceLessThan
                        }}</span
                      >&ensp;&ensp;
                      <span
                        v-if="
                          (scope.row.asinPriceLessThan != '' &&
                            scope.row.asinPriceGreaterThan == '') ||
                          (scope.row.asinPriceGreaterThan != '' &&
                            scope.row.asinPriceLessThan == '')
                        "
                        >商品价格：
                        <span v-if="scope.row.asinPriceLessThan != ''"
                          >不高于 {{ scope.row.asinPriceLessThan }}</span
                        >
                        <span v-if="asinPriceGreaterThan != ''"
                          >不低于 {{ scope.row.asinPriceGreaterThan }}</span
                        > </span
                      >&nbsp;&nbsp;
                      <span
                        v-if="
                          scope.row.prime != undefined && scope.row.prime != ''
                        "
                        >配送：{{ scope.row.prime }}</span
                      >&nbsp;&nbsp;
                      <span
                        v-if="
                          scope.row.asinReviewRatingBetween != undefined &&
                          scope.row.asinReviewRatingBetween.length != 0
                        "
                        >评分：{{
                          scope.row.asinReviewRatingBetween[0] +
                          "-" +
                          scope.row.asinReviewRatingBetween[1]
                        }}</span
                      >
                    </p>
                    <p
                      v-if="
                        scope.row.amzAdTargetExpressionList[0].type ==
                        'asinSameAs'
                      "
                    >
                      {{ scope.row.amzAdTargetExpressionList[0].name }}
                    </p>
                  </div>
                  <div
                    v-if="scope.row.yet == 1"
                    v-dompurify-html="scope.row.value"
                    style="font-size: 14px"
                  ></div>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                prop="prop"
                label="竞价"
                width="170"
              >
                <template slot-scope="scope">
                  <el-input
                    :disabled="scope.row.yet == 1 ? true : false"
                    style="width: 120px"
                    v-model="scope.row.bid"
                    placeholder="竞价"
                  > <span slot="append">{{currency}}</span>
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="prop"
                label="删除"
                width="70"
              >
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.yet != 1"
                    @click="remove(scope.$index, addInfo.targetAddReqList)"
                    style="font-size: 23px; color: #409eff"
                    class="el-icon-delete"
                  ></span>
                  <span
                    v-if="scope.row.yet == 1"
                    style="color: #999999; font-size: 14px"
                    >已存在</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: right; margin: 15px 0 15px">
              <el-button
                type="primary"
                :loading="buttonLoading"
                @click="NewProductPositioning"
                >确认新增商品定位</el-button
              >
            </div>
          </div>
        </div>

        <!-- 底部折叠区域 -->
        <div class="words">
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <span style="margin-left: 20px">否定商品</span>
              </template>
              <div
                class="keyword"
                style="border-top: 1px solid #f0f0f0; padding: 0 10px"
              >
                <div>
                  <el-tabs v-model="noGoodsType" @tab-click="noGoods = ''">
                    <el-tab-pane
                      v-if="putStatus == '商'"
                      label="排除品牌"
                      name="asinBrandSameAs"
                    >
                      <span slot="label"
                        >排除品牌
                        <el-tooltip placement="top">
                          <div
                            slot="content"
                            style="width: 250px; line-height: 19px"
                          >
                            <p>
                              这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。
                            </p>
                          </div>
                          <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                      </span>
                    </el-tab-pane>
                    <el-tab-pane label="排除全部" name="asinSameAs">
                      <span slot="label"
                        >排除全部
                        <el-tooltip placement="top">
                          <div
                            slot="content"
                            style="width: 250px; line-height: 19px"
                          >
                            <p>
                              这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。
                            </p>
                          </div>
                          <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                      </span>
                    </el-tab-pane>
                  </el-tabs>
                  <div v-if="noGoodsType == 'asinSameAs'">
                    <el-input
                      v-model="noGoods"
                      style="margin-top: 10px"
                      rows="8"
                      type="textarea"
                      placeholder="手动输入ASIN，多个时换行输入"
                    ></el-input>
                    <el-button
                      @click="toTargetsBrandsList"
                      style="float: right; margin: 10px 0px 0 15px"
                      >添加</el-button
                    >
                  </div>
                  <div
                    v-if="noGoodsType == 'asinBrandSameAs' && putStatus == '商'"
                  >
                    <el-input
                      placeholder="请输入内容"
                      v-model="targetsBrandsInfo.keyword"
                      class="input-with-select"
                      style="margin-top: 10px"
                    >
                      <el-button
                        @click="getTargetsBrandsList"
                        slot="append"
                        icon="el-icon-search"
                      ></el-button>
                    </el-input>
                    <div>
                      <el-table
                        v-loading="searchLoading"
                        height="300px"
                        :show-header="false"
                        :data="targetsBrandsList"
                        style="width: 100%"
                        cell-style="font-size:14px"
                        header-cell-style="background:#FAFAFA;font-size:14px"
                      >
                        <el-table-column
                          prop="name"
                          label="label"
                          width="width"
                        >
                        </el-table-column>
                        <el-table-column prop="prop" label="label" width="50">
                          <template slot-scope="scope">
                            <span
                              v-if="scope.row.operation == 0"
                              @click="
                                toTargetsBrandsList(scope.row, scope.$index)
                              "
                              style="font-size: 23px; color: #409eff"
                              class="el-icon-circle-plus-outline"
                            ></span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
                <div v-loading="dialogLoading">
                  <!-- <span
                    style="margin-right: 10px; color: #409eff; cursor: pointer"
                    v-show="addedNoGoods != 0"
                    @click="archivedNeTarget"
                    >归档</span
                  > -->
                  <!-- <span
                    >已存在{{ addedNoGoods }}个，并添加{{
                      addInfo.neTargetAddReqList.length - addedNoGoods
                    }}</span
                  > -->
                  <el-button
                    type="text"
                    style="float: right"
                    @click="delAllnoGood"
                    >删除全部</el-button
                  >
                  <el-table
                    :data="addInfo.neTargetAddReqList"
                    style="width: 100%"
                    header-cell-style="background:#DFEFEFE;font-size:14px"
                  >
                    <el-table-column
                      type="selection"
                      width="50"
                      :selectable="archivedSelect"
                    ></el-table-column>
                    <el-table-column
                      prop="prop"
                      label="品牌/商品"
                      width="width"
                    >
                      <template slot-scope="scope">
                        <p style="color: #999999; font-size: 14px">
                          <span
                            v-if="
                              scope.row.type == 'asinBrandSameAs' &&
                              scope.row.yet != 1
                            "
                            >品牌：{{ scope.row.name }}</span
                          >
                          <span
                            v-if="
                              scope.row.type == 'asinSameAs' &&
                              scope.row.yet != 1
                            "
                            >ASIN：{{ scope.row.value }}</span
                          >
                          <span v-if="scope.row.yet == 1">
                            <span v-if="scope.row.type == 'asinBrandSameAs'"
                              >品牌：</span
                            >
                            <span v-if="scope.row.type == 'asinSameAs'"
                              >ASIN：</span
                            >
                            {{ scope.row.value }}
                          </span>
                        </p>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="prop"
                      align="center"
                      header-align="center"
                      label="删除"
                      width="70"
                    >
                      <template slot-scope="scope">
                        <span
                          v-if="scope.row.yet != 1"
                          style="font-size: 23px; color: #409eff"
                          class="el-icon-delete"
                          @click="delItem(scope.$index, scope.row.name)"
                        ></span>
                        <span
                          style="color: #999999; font-size: 14px"
                          v-if="scope.row.yet == 1"
                          >已存在</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div
                style="
                  text-align: right;
                  margin-right: 40px;
                  margin-bottom: 20px;
                "
              >
                <el-button type="primary" @click="addNeTargets"
                  >确认新增否定商品定位</el-button
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-form>
    </el-dialog>
    <!-- 批量调整竞价 -->
    <el-dialog
      center
      width="600px"
      title="批量调整竞价"
      :visible.sync="batchAdjustBidVisible"
    >
      <el-form :inline="true" ref="ruleForm" :model="formInline">
        <el-form-item>
          <el-select
            style="width: 260px"
            v-model="formInline.selectValue"
            @change="changeOperateType"
          >
            <el-option
              v-for="item in adjustBidOperateTypeList"
              :key="item.id"
              :label="item.values"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            type="number"
            style="width: 260px"
            v-model="formInline.numberValue"
            placeholder="请输入金额"
          >
            <i slot="suffix" class="el-input__icon" v-show="percentShow">
              <img
                src="../../../src/assets/common/percent.png"
                style="width: 26px; height: 26px; margin-top: 5px"
              />
            </i>
            <i slot="prefix" class="el-input__icon" v-show="rmbShow">
              <img
                src="../../../src/assets/common/rmb.png"
                style="width: 18px; height: 18px; margin-top: 8px"
              />
            </i>
          </el-input>
        </el-form-item>
        <div>
          <el-row style="color: red; font-size: 16px">说明:</el-row>
          <el-row style="margin: 20px 0; font-size: 16px"
            >1: 将竞价统一调整到: 输入的金额就是目标竞价;</el-row
          >
          <el-row style="margin: 20px 0; font-size: 16px"
            >2: 将竞价按百分比提高/降低: 目标竞价 = (1 ± 输入值) / 100 *
            当前的竞价;</el-row
          >
          <el-row style="margin: 20px 0; font-size: 16px"
            >3: 将竞价按固定金额增加/减少: 目标竞价 = 输入的金额 ±
            当前的竞价;</el-row
          >
          <el-row style="margin: 20px 0; font-size: 16px"
            >4: 调整后的目标竞价, 小于2时, 系统会将目标竞价调整为2,
            大于100000时, <br />系统调整为100000;</el-row
          >
          <el-row style="font-size: 16px"
            >5: 调整后的目标竞价, 按照四舍五入最多保留两位小数;</el-row
          >
        </div>
        <el-row class="botton_group_bottom">
          <el-button @click="batchAdjustBidVisible = false">取消</el-button>
          <el-button type="primary" @click="asyncSubmit('ruleForm')"
            >确定</el-button
          >
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 批量应用建议竞价 -->
    <el-dialog
      center
      width="600px"
      title="批量应用建议竞价"
      :visible.sync="batchApplicationVisible"
    >
      <span style="font-size: 16px"
        >您确定要把选中的&nbsp{{
          multipleSelection.length
        }}&nbsp个自动投放的关键词的竞价, 调整为亚马逊建议竞价吗？
      </span>
      <el-row class="botton_group_bottom">
        <el-button @click="batchApplicationVisible = false">取消</el-button>
        <el-button type="primary" @click="asyncConfirm()">确定</el-button>
      </el-row>
    </el-dialog>
    <!-- 批量暂停广告组 -->
    <el-dialog
      center
      width="600px"
      title="批量暂停广告组"
      :visible.sync="batchSuspendVisible"
    >
      <span style="font-size: 16px"
        >您确定要把选中的&nbsp{{
          multipleSelection.length
        }}&nbsp个启动状态的关键词, 调整为<span style="color: #ff0000">暂停</span
        >状态吗?</span
      >
      <el-row class="botton_group_bottom">
        <el-button @click="batchSuspendVisible = false">取消</el-button>
        <el-button type="primary" @click="asyncSubmit()">确定</el-button>
      </el-row>
    </el-dialog>
    <!-- 批量启动广告组 -->
    <el-dialog
      center
      width="600px"
      title="批量启动广告组"
      :visible.sync="batchStartVisible"
    >
      <span style="font-size: 16px"
        >您确定要把选中的&nbsp{{
          multipleSelection.length
        }}&nbsp个暂停状态的关键词, 调整为<span style="color: #008000">启动</span
        >状态吗?</span
      >
      <el-row class="botton_group_bottom">
        <el-button @click="batchStartVisible = false">取消</el-button>
        <el-button type="primary" @click="asyncSubmit()">确定</el-button>
      </el-row>
    </el-dialog>
    <!-- 批量归档广告组 -->
    <el-dialog
      center
      width="600px"
      title="批量归档广告组"
      :visible.sync="batchArchiveVisible"
    >
      <span style="font-size: 16px"
        >您确定要把选中的&nbsp;{{ multipleSelection.length }}&nbsp个关键词,
        <span style="color: #0000ff">归档</span>吗?</span
      >
      <el-row class="botton_group_bottom">
        <el-button @click="batchArchiveVisible = false">取消</el-button>
        <el-button type="primary" @click="asyncSubmit()">确定</el-button>
      </el-row>
    </el-dialog>

    <!-- 底部抽屉区域 -->
    <el-drawer
      title="投放表现"
      :modal="false"
      size="60%"
      :visible.sync="drawer"
      :direction="direction"
      :custom-class="DrawerClass"
      :before-close="handleClose"
    >
      <TendencyChart
        :list.sync="ListOfData"
        :info="`投放方式:${adCampaignName}  广告组:${adGroupName}`"
        :legendList="legendList"
        :currency="currency"
        @analysisClick="analysisClick"
      ></TendencyChart>
    </el-drawer>
  </div>
</template>

<script>
import TimeQuantum from "@/components/TimeQuantum";
import TendencyChart from "@/views/Advertising/TendencyChart";
import {
  pageInit, // 查询初始化
  listPage,
} from "@/api/stockControl.js";
import {
  focusOn,
  focusOff,
  listNegativeTarget,
  listByGroupId,
  addNeTargets,
  BatchKeyword,
  pageInits,
  getListPage,
  addInit,
  stockManage,
  goodsType,
  getTargetProductRecommendations,
  listExistTarget,
  addTargets,
  groupDetailInit,
  getTargetsBrands,
  modifyBid,
  getKeywordBidRecommendations,
  listByTarget,
} from "@/api/launch.js";
export default {
  components: {
    TimeQuantum,
    TendencyChart,
  },
  data() {
    return {
      dialogLoading:false,
      searchByAsin: "",
      drawer: false,
      direction: "btt",
      list: [],
      targetProductList: [],
      inputASIN: "",
      putTypeInside: "first",
      putType: "asinCategorySameAs",
      putStatuss: "",
      putStatus: "",
      addInfo: {
        expressionType: "",
        campaignId: "",
        adGroupId: "",
        arrList: [],
        screenGroupsList: [],
        campaignsList: [],
        targetAddReqList: [],
        neKeywordAddReqList: [],
        neTargetAddReqList: [],
        keywordAddReqList: [],
        uploadedKeywordAddReqList: [],
        ExistingGoodsList: [],
        elaborationRow: "",
      },
      suggested: "",
      rangeStart: "",
      rangeEnd: "",
      brandList: "",
      detailTypeList: "",
      batchStartVisible: false, // 批量启动
      batchArchiveVisible: false, // 批量归档
      elaborationBox: false,
      batchSuspendVisible: false,
      CurSelecteName: "first",
      show_mainContent: false,
      campaignStateList: [],
      favoriteTypeList: [],
      targetsBrandsList: [],
      targetsBrandsInfo: {
        keyword: "",
        categoryId: "",
      },
      items: 0,
      radio: "3",
      mark: [2, 5],
      marks: { 0: "0星", 1: "1星", 2: "2星", 3: "3星", 4: "4星", 5: "5星" },
      value1: 0,
      xihuaa: false,
      formm: {
        name: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        region: "",
      },
      rightData: [],
      leftdata: [],
      listGet: [],
      left_Tdata: [],
      inputList: "子ASIN",
      activeName: "first",
      goodsList: [],
      addedList: [],
      goodsTotal: "",
      arrLists: [],
      arrObject: {},
      detailsObject: {},
      batchApplicationVisible: false,
      adjustBidOperateTypeList: [],
      formInline: {
        selectValue: "1",
        numberValue: "",
      },
      batchAdjustBidVisible: false,
      switchValue: "",
      arrList: [],
      tableDataList: [],
      adActivity: [],
      targetTypeList: [],
      targetStateList: [],
      groupStateList: [],
      groupLists: [],
      obj: [],
      total: 0,
      radioState: "",
      total_number: 0, // 总个数
      show_cont: false,
      show_adGroup: false,
      text_Field: "",
      loading: false,
      type: 0, // type: 1 查看  type 2 隐藏
      Selecte_Name: "first",
      CurSelecteItem: "first",
      active_Item: "advice",
      active_current: "hasBeenSuggest",
      manually_Enter: "", // 手动输入文本域
      pageInfo: {
        adActiveState: "", // 所有的活动状态
        storeName: "", // 店铺
        adCombination: "", // 选择组合
        adActivity: "", //  广告活动
        adGroup: "", //  选择组合
        targetState: "", // 投放状态
        adActivityStatus: "", // 广告活动状态
        allPutOn: "", // 所有投放
        attentionState: "", //  关注状态
        categoryName: "", //  类目名称
        current: "1",
        pageSize: 20,
        profileId: "",
        shopId: "",
        marketplaceId: "",
        isFavorite: "",
        targets: "",
        sort: "",
        sortColumn: "",
        startTime: "",
        endTime: "",
      },
      noGoodsList: [],
      targetIds: [],
      tableData: [],
      activeNames: ["1"],
      multipleSelection: [],
      adCampaignData: [],
      tDataList: [], // 底部表格区域
      goodsStatusList: [],
      subASINList: [],
      left_Search_Data: [],
      right_Tdata: [],
      positionGoodsList: [],
      manageGoodsVisible: false,
      labelPosition: "right",
      formLabelAlign: {
        adActivity: "", // 广告活动
        campaignId: "",
        adGroupId: "",
      },
      formList: {
        status: "", // 商品状态
        current: "1",
        queryFrom: "advert",
      },
      textField: "",
      search_content: "",
      keyword: "", // 关键词
      select_Asin: "",
      // 1639002340771842
      adCampaignId: "", // 广告活动id
      params: {
        profileId: "",
        current: "",
        pageSize: "",
        startDate: "",
        endDate: "",
        campaignId: "",
        groupId: "",
        targetState: "",
        expressionType: "",
        isFavorite: "",
        keyword: "",
      },
      listPrice: [],
      batchInfo: {
        operateType: "",
      },
      keywordIdList: [],
      suggestLoading: false,
      primeTypeList: "",
      adVertisCampaignList: [],
      arrayDataList: [],
      adCollectionList: [],
      typeList: [],
      addNeTargetsInfo: {
        adGroupId: "",
        amzAdTargetExpressionList: [],
        campaignId: "",
        expressionType: "manual",
      },
      noGoods: "",
      noGoodsType: "asinSameAs",
      legendList: [
        { name: "曝光量", prop: "impressions" },
        { name: "点击量", prop: "clicks" },
        { name: "点击率", prop: "clickRate", persent: true },
        { name: "花费", prop: "cost", currency: true },
        { name: "CPC均价", prop: "clickAveCost", currency: true },
        { name: "订单数", prop: "attributedConversions14d" },
        { name: "CVR", prop: "cvr", persent: true },
        { name: "CPA", prop: "cpa", currency: true },
        { name: "销售额", prop: "attributedSales14d" },
        { name: "ACoS", prop: "acos", persent: true },
        { name: "RoAS", prop: "roas", persent: true },
      ],
      adCampaignName: "",
      adGroupName: "",
      ListOfData: [],
      shopId: "",
      marketPlaceId: "",
    };
  },
  // 生命周期
  created() {
    this.getData();
    setTimeout(() => {
      this.judgeCombina();
      this.judgeActivity();
    }, 1000);
  },
  watch: {
    $route(to) {
      this.judgeActivity();
      let queryInfo = this.$route.query;
      if (JSON.stringify(queryInfo) != "{}") {
        if (queryInfo.type == "getList") {
          this.pageInfo.profileId = Number(queryInfo.profileId);
          this.pageInfo.adActivity = Number(queryInfo.campaignId);
          this.groupList.forEach((item) => {
            // 广告活动初始化
            if (item.id == queryInfo.id) {
              this.pageInfo.adGroup = item.id;
            }
          });
          this.handleSearch();
        }
      } else {
        this.handleSearch();
      }
    },
  },
  mounted() {},
  // 事件处理函数
  methods: {
    NewProductPositioning() {
      this.buttonLoading = true;
      // 考虑细化新增
      let arr = this.addInfo.targetAddReqList.filter((item) => {
        return item.yet != 1;
      });
      let list = arr.map((item) => {
        if (item.name == undefined) {
          return item;
        }
        if (item.name != undefined) {
          let postList = [];
          item.amzAdTargetExpressionList.push({
            name: item.name,
            type: "asinBrandSameAs",
            value: item.id,
          });
          // 区间或价格
          if (item.asinPriceLessThan != "" && item.asinPriceGreaterThan != "") {
            item.amzAdTargetExpressionList.push({
              name: item.asinPriceGreaterThan + "-" + item.asinPriceLessThan,
              type: "asinPriceBetween",
              value: item.asinPriceGreaterThan + "-" + item.asinPriceLessThan,
            });
          } else if (
            item.asinPriceLessThan != "" &&
            item.asinPriceGreaterThan == ""
          ) {
            item.amzAdTargetExpressionList.push({
              name: item.asinPriceLessThan,
              type: "asinPriceLessThan",
              value: item.asinPriceLessThan,
            });
          } else if (
            item.asinPriceLessThan == "" &&
            item.asinPriceGreaterThan != ""
          ) {
            item.amzAdTargetExpressionList.push({
              name: item.asinPriceGreaterThan,
              type: "asinPriceGreaterThan",
              value: item.asinPriceGreaterThan,
            });
          }
          // 星数
          if (item.asinReviewRatingBetween != "") {
            item.amzAdTargetExpressionList.push({
              name:
                item.asinReviewRatingBetween[0] +
                "-" +
                item.asinReviewRatingBetween[1],
              type: "asinReviewRatingBetween",
              value:
                item.asinReviewRatingBetween[0] +
                "-" +
                item.asinReviewRatingBetween[1],
            });
          }
          // 配送
          if (item.asinIsPrimeShippingEligible != "") {
            item.amzAdTargetExpressionList.push({
              name: item.asinIsPrimeShippingEligible,
              type: "asinIsPrimeShippingEligible",
              value: item.asinIsPrimeShippingEligible,
            });
          }
          postList = {
            amzAdTargetExpressionList: item.amzAdTargetExpressionList,
            bid: item.bid,
          };
          return postList;
        }
      });
      addTargets(
        {
          adGroupId: this.addInfo.adGroupId,
          campaignId: this.addInfo.campaignId,
          expressionType: this.addInfo.expressionType,
          targets: list,
        },
        this.pageInfo.profileId
      ).then((res) => {
        this.buttonLoading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getListExistTarget();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getTargetProduct(arr) {
      this.suggestedLoading = true;
      getTargetProductRecommendations(arr, this.pageInfo.profileId).then(
        (res) => {
          this.suggestedLoading = false;
          this.targetProductList = res.data.data.map((item) => {
            item.operation = 0;
            return item;
          });
          if (this.addInfo.targetAddReqList.length != 0) {
            this.addInfo.targetAddReqList.forEach((i) => {
              this.targetProductList.forEach((j) => {
                if (
                  j.recommendedTargetAsin == i.amzAdTargetExpressionList[0].name
                ) {
                  j.operation = 1;
                }
              });
            });
          }
        }
      );
    },
    remove(index, row) {
      this.left_Tdata.forEach((i) => {
        if (row[index].amzAdTargetExpressionList[0].name == i.name) {
          i.operation = 0;
        }
      });
      this.targetProductList.forEach((i) => {
        if (
          row[index].amzAdTargetExpressionList[0].name ==
          i.recommendedTargetAsin
        ) {
          i.operation = 0;
        }
      });
      this.addInfo.targetAddReqList.splice(index, 1);
    },
    removeAll() {
      this.addInfo.targetAddReqList.forEach((item, index) => {
        if (item.yet != 1) {
          this.addInfo.targetAddReqList.splice(index, 1);
        }
      });
      this.getListExistTarget();
    },
    getListExistTarget() {
      this.dialogLoading = true;
      listExistTarget({ groupId: this.addInfo.adGroupId }).then((res) => {
        this.dialogLoading = false;
        if (res.data.code != 200) {
          this.$message.error(res.data.message);
          return;
        }
        this.addInfo.targetAddReqList = res.data.data.map((item) => {
          item.yet = 1;
          return item;
        });
      });
    },
    handleClicks(tab, event) {
      this.putType = tab.name;
      if (tab.name == "asinCategorySameAs") {
        this.putTypeInside = "first";
      } else {
        this.putTypeInside = "second";
      }
    },
    //关注与取消关注
    focusChange(id, status, index) {
      this.loading = true;
      if (status == 0) {
        focusOn(id).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.tableData[index].focusOn = 1;
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
      if (status == 1) {
        focusOff(id).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.tableData[index].focusOn = 0;
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    //新的 中间 添加
    addLocationGoods(rows) {
      rows.operation = 1;
      console.log("nide", rows.operation);
      if (this.putType == "asinCategorySameAs") {
        this.addInfo.targetAddReqList.push({
          amzAdTargetExpressionList: [
            { name: rows.name, value: rows.id, type: this.putType },
          ],
          bid: "",
          path: rows.path,
        });
      }
      if (this.putType == "asinSameAs") {
        if (this.inputASIN != "") {
          this.addInfo.targetAddReqList.push({
            amzAdTargetExpressionList: [
              {
                name: this.inputASIN,
                type: this.putType,
                value: this.inputASIN,
              },
            ],
            bid: "",
          });
        } else {
          this.addInfo.targetAddReqList.push({
            amzAdTargetExpressionList: [
              {
                name: rows.recommendedTargetAsin,
                type: this.putType,
                value: rows.recommendedTargetAsin,
              },
            ],
            bid: "",
          });
        }
      }
      this.inputASIN = "";
    },
    changeOperateType(val) {
      this.formInline.selectValue = val;
      if (val == 2 || val == 3) {
        this.percentShow = true;
        this.rmbShow = false;
      } else {
        this.percentShow = false;
        this.rmbShow = true;
      }
    },
    // 根据组合选择广告活动
    change_event(id) {
      this.campaignsList.forEach((item) => {
        if (item.portfolioId == id) {
          this.list.push(item);
        }
      });
      this.adVertisCampaignList = this.list;
      this.pageInfo.adActivity = "";
    },
    // 根据广告活动筛选选择组
    handle_event(adCombination, id) {
      this.groupList.forEach((item) => {
        if (id == item.campaignId) {
          this.typeList.push(item);
        }
      });
      this.adCollectionList = this.typeList;
      this.pageInfo.adGroup = "";
    },
    //细化操作
    getGroupDetailInit(id) {
      this.suggestLoading = true;
      groupDetailInit({ categoryId: id }, this.pageInfo.profileId).then(
        (res) => {
          this.suggestLoading = false;
          if (res.data.code == 200) {
            this.elaborationBox = true;
            this.brandList = res.data.data.brandList;
            this.detailTypeList = res.data.data.detailTypeList;
            this.primeTypeList = res.data.data.primeTypeList;
          }
        }
      );
    },
    // 建议竞价
    onObtainBtn(index, targetId) {
      this.loading = true;
      getKeywordBidRecommendations(targetId, this.pageInfo.profileId).then(
        (res) => {
          this.loading = false;
          if (res.data.code == 200) {
            if (res.data.data == "") {
              this.$message.warning("暂无建议竞价");
            }
            this.$set(
              this.tableData[index],
              "suggested",
              res.data.data.suggested
            );
            this.$set(
              this.tableData[index],
              "rangeEnd",
              res.data.data.rangeEnd
            );
            this.$set(
              this.tableData[index],
              "rangeStart",
              res.data.data.rangeStart
            );
          } else if (res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    //批量操作确定
    asyncSubmit() {
      if (this.commandId != 1) {
        if (this.commandId == 2) {
          let data = {
            targetIds: this.keywordIdList,
            operateType: this.commandId,
          };
          BatchKeyword(data, this.pageInfo.profileId).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.batchApplicationVisible = false;
              //  this.handleSearch();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          });
        } else if (this.commandId == 3) {
          let data = {
            targetIds: this.keywordIdList,
            operateType: this.commandId,
          };
          BatchKeyword(data, this.pageInfo.profileId).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.batchSuspendVisible = false; 
              // this.handleSearch();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          });
        } else if (this.commandId == 4) {
          let data = {
            targetIds: this.keywordIdList,
            operateType: this.commandId,
          };
          BatchKeyword(data, this.pageInfo.profileId).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.batchStartVisible = false; 
              // this.handleSearch();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          });
        } else if (this.commandId == 5) {
          let data = {
            targetIds: this.keywordIdList,
            operateType: this.commandId,
          };
          BatchKeyword(data, this.pageInfo.profileId).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.batchArchiveVisible = false; 
              // this.handleSearch();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          });
        }
      } else {
        if (
          this.formInline.selectValue == 2 ||
          this.formInline.selectValue == 3
        ) {
          let data = {
            defaultBidUpdateReq: {
              operateType: this.formInline.selectValue,
              percent: this.formInline.numberValue,
            },
            targetIds: this.keywordIdList,
            operateType: this.commandId,
          };
          BatchKeyword(data, this.pageInfo.profileId).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.batchAdjustBidVisible = false;
              // this.handleSearch();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          });
        } else {
          let data = {
            defaultBidUpdateReq: {
              operateType: this.formInline.selectValue,
              amount: this.formInline.numberValue,
            },
            targetIds: this.keywordIdList,
            operateType: this.commandId,
          };
          BatchKeyword(data, this.pageInfo.profileId).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.message);
              this.batchAdjustBidVisible = false;
              // this.handleSearch();
            } else if (res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          });
        }
      }
    },
    //细化弹框
    xihua(item) {
      this.xihuaa = true;
    },
    // 弹框初始化
    getLocationInitData() {
      pageInit().then((res) => {
        if (res.data.code == 200) {
          this.goodsStatusList = res.data.data.productStatusList;
          this.subASINList = res.data.data.searchList;
          this.select_Asin = this.subASINList[0].id;
        }
      });
    },

    // 判断组合id 是否为空
    judgeCombina() {
      if (!this.pageInfo.adCombination) {
        this.adVertisCampaignList = this.campaignsList;
      } else {
        this.adVertisCampaignList = this.list;
      }
    },
    // 判断活动id 是否存在
    judgeActivity() {
      if (!this.pageInfo.adActivity) {
        this.adCollectionList = this.groupList;
      } else {
        this.adCollectionList = this.typeList;
      }
    },
    //新增初始化
    async getData2() {
      try {
        const res = await addInit(this.pageInfo.profileId);
        if ((Object.is(res.code), 200)) {
          this.adCampaignData = res.data.data.campaignList;
          this.arrList = res.data.data.groupList;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //库存管理
    async getData3() {
      try {
        const res = await stockManage({
          ...this.formList,
          [this.select_Asin]: this.searchByAsin ? this.searchByAsin : null,
          shopId: this.pageInfo.shopId,
          marketPlaceId: this.pageInfo.marketplaceId,
        });
        console.log(res);
        this.goodsList = [];
        if ((Object.is(res.code), 200)) {
          const arr = res.data.values.map((item) => {
            item.operation = 0;
            return item;
          });
          if (this.rightData.length != 0) {
            const ids = this.rightData.map((item) => {
              return item.id;
            });
            arr.forEach((item) => {
              ids.forEach((data) => {
                if (data == item.id) {
                  item.operation = 1;
                }
              });
            });
          }
          this.goodsList = arr;
          this.goodsTotal = res.data.pageInfo.total;
          // this.getTargetProduct()
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //搜索获取品牌
    async getData7() {
      try {
        const res = await getTargetsBrands(
          this.targetsBrandsInfo,
          this.pageInfo.profileId
        );
        if ((Object.is(res.code), 200)) {
          const arr = res.data.data.map((item) => {
            item.operation = 0;
            return item;
          });
          if (this.tDataList.length != 0) {
            const ids = this.tDataList.map((item) => {
              return item.id;
            });
            arr.forEach((item) => {
              ids.forEach((i) => {
                if (i == item.id) {
                  item.operation = 1;
                }
              });
            });
          }
          this.targetsBrandsList = arr;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //搜索按钮
    searchTarget() {
      this.getData7();
    },
    //单加
    toAdded(item) {
      item.operation = 1;
      this.rightData.push({ ...item, operation: 1 });
      this.asyncSearch();
      const arr = [];
      this.rightData.forEach((i) => {
        arr.push(i.asin);
      });
      //分类建议data
      this.getGoodsType(arr)
      this.getTargetProduct(arr);
    },
    getGoodsType(arr){
      goodsType(this.pageInfo.profileId, arr).then((res) => {
        console.log(res);
        this.left_Tdata = [];
        if (res.data.code == 200) {
          const arr = res.data.data.map((item) => {
            item.operation = 0;
            return item;
          });
          this.left_Tdata = arr;
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      })
    },
    //单删
    toGoods(item, _index) {
      item.operation = 0;
      this.rightData.splice(_index, 1);
      this.asyncSearch();
      // this.getData3();
    },
    //分类建议的单独添加
    toAdd(item) {
      item.operations = 1;
      if (item != undefined) {
        this.listGet.push({ ...item, operations: 1 });
      }
      const arr = [];
      this.listGet.forEach((i) => {
        arr.push(i.asin);
      });
    },
    //分类的单独删除
    toGood(_index) {
      this.items = 0;
      this.listGet.pop(_index, 1);
    },
    //全选或全删操作
    allInt(info) {
      if (info == "del") {
        this.rightData.forEach((item) => {
          item.operation = 0;
        });
        this.rightData = [];
      } else {
        const arr = this.goodsList.filter((item) => {
          return item.operation == 0;
        });
        arr.forEach((item) => {
          item.operation = 1;
        });
        this.rightData = [...this.rightData, ...arr];
      }
    },
    //店铺选择
    checkGroup(parentId, subId, profileId, currency) {
      this.adVertisCampaignList = [],
      this.adCollectionList = [],
      (this.pageInfo = {
        adActiveState: "",
        adCombination: "",
        adActivity: "",
        adGroup: "",
        targetState: "",
        adActivityStatus: "",
        allPutOn: "",
        attentionState: "",
        categoryName: "",
        current: "",
        pageSize: 20,
        profileId: null,
        shopId: "",
        marketplaceId: "",
        isFavorite: "",
        targets: "",
        sortColumn: "",
        startTime: this.pageInfo.startTime,
        endTime: this.pageInfo.endTime,
      }),
      this.pageInfo.profileId = profileId;
      this.pageInfo.shopId = parentId;
      this.pageInfo.marketplaceId = subId;
      // this.shopId = parentId;
      // this.marketplaceId = subId;
      this.currency = currency;
      this.getData("currentPage");
    },

    //查询初始化
    getData(val) {
      pageInits(
        this.pageInfo.profileId || this.$store.state.profileId
          ? this.pageInfo.profileId || this.$store.state.profileId
          : 0
      ).then((res) => {
        if (res.data.code == 200) {
          this.obj = res.data;
          this.operateTypeList = res.data.data.operateTypeList;
          this.campaignStateList = res.data.data.campaignStateList;
          this.campaignsList = res.data.data.campaignsList;
          this.portfoliosList = res.data.data.portfoliosList;
          this.groupList = res.data.data.groupList;
          this.groupLists = res.data.data.shopList;
          this.targetStateList = res.data.data.targetStateList;
          this.targetTypeList = res.data.data.targetTypeList;
          this.favoriteTypeList = res.data.data.favoriteTypeList;
          this.adjustBidOperateTypeList =
            res.data.data.adjustBidOperateTypeList;
          // this.groupLists.forEach((item) => {
          //   item.marketPlaceTree.forEach((data) => {
          //     if (data.isSelected === 1) {
          //       this.pageInfo.profileId = data.profileId;
          //       this.currency = data.currency;
          //       this.shopID = item.id;
          //       this.marketPlaceID = data.id;
          //     }
          //   });
          // });
          this.groupLists.forEach((item) => {
            item.marketPlaceTree.forEach((i) => {
              if (i.isSelected == 1) {
                if (this.pageInfo.profileId == "") {
                  this.pageInfo.profileId = i.profileId;
                  this.currency = i.currency;
                  // this.shopID = item.id;
                  // this.marketPlaceID = i.id;
                }
                this.pageInfo.current = 1;
              }
            });
          });
        } else if (res.data.code == 500) {
          console.log(res.data.code);
          this.$message.error(res.data.message);
        }
        if (val == "currentPage") {
          this.handleSearch();
        } else {
          this.judgeActivity();
          let getInfo = this.$route.query;
          if (JSON.stringify(getInfo) != "{}") {
            if (getInfo.type == "getList") {
              this.pageInfo.profileId = Number(getInfo.profileId);
              this.pageInfo.adActivity = Number(getInfo.campaignId);
              this.pageInfo.adGroup = Number(getInfo.id);
              this.handleSearch();
            }
          } else {
            this.handleSearch();
          }
        }
      });
    },
    clickBtn(type) {
      if (type == 1) {
        this.type = 2;
        this.show_cont = true;
      } else if (type == 2) {
        this.type = 1;
        this.show_cont = false;
      }
    },
    handleClick(data) {
      if (data == 1) {
        this.data = 2;
        this.show_adGroup = true;
      } else if (data == 2) {
        this.data = 1;
        this.show_adGroup = false;
      }
    },
    // 查询事件
    handleSearch: function () {
      this.loading = true;
      this.$nextTick(() => {});
      // 组装请求参数
      let params = {
        expressionType: this.pageInfo.allPutOn,
        current: this.pageInfo.current,
        pageSize: this.pageInfo.pageSize, //  每页显示多少条
        shopId: this.pageInfo.shopId,
        marketplaceId: this.pageInfo.marketplaceId,
        startDate: this.pageInfo.startDate,
        endDate: this.pageInfo.endDate,
        portfolioId: this.pageInfo.adCombination, //广告组合id
        campaignId: this.pageInfo.adActivity, // 广告活动id
        groupsId: this.pageInfo.adGroup, //  广告组id
        targetType: this.pageInfo.adTypes, //  匹配类型
        campaignStatus: this.pageInfo.adActiveState, //  广告活动状态
        profileId: this.pageInfo.profileId,
        // keyword: this.pageInfo.keyWords,
        isFavorite: this.pageInfo.isFavorite, // 关注状态
        targetState: this.pageInfo.targetState,
        targets: this.pageInfo.targets,
        categoryName: this.pageInfo.categoryName,
        sort: this.pageInfo.sort,
        sortColumn: this.pageInfo.sortColumn,
      };
      getListPage(params).then((res) => {
        this.loading = false;
        this.$store.commit("setProfileId", this.pageInfo.profileId);
        this.tableData = [];

        this.tableData = res.data.values;
        this.total = res.data.pageInfo.total;
      });
    },
    topTime(val) {
      this.pageInfo.startDate = val.value[0];
      this.pageInfo.endDate = val.value[1];
    },
    // 弹框立即查询
    asyncSearch() {
      this.getData3();
    },
    // 管理商品定位
    handleManageGoods() {
      
      this.formLabelAlign.adActivity = "";
      this.formLabelAlign.adGrounp = "";
      this.formList.status = "";
      this.select_Asin = "";
      this.keyword = "";
      this.show_div = false;
      this.show_adGroup = false;
      this.show_mainContent = false;
      this.show_cont = false;
      this.getLocationInitData();
      this.manageGoodsVisible = true;
      addInit(this.pageInfo.profileId).then((res) => {
        if (res.data.code == 200) {
          this.adCampaignData = res.data.data.campaignList;
          this.arrList = res.data.data.groupList;
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },
    // 批量操作
    handleBatch() {},
    // table 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      var targetIds = [];
      this.multipleSelection.forEach((data) => {
        targetIds.push(data.targetId);
      });
      this.keywordIdList = targetIds;
    },
    handleSizeChange(newSize) {
      this.pageInfo.pageSize = newSize;
      this.handleSearch();
    },
    handleCurrentChange(newCurrent) {
      this.pageInfo.current = newCurrent;
      this.handleSearch();
    },
    handleCommand(command) {
      this.commandId = command;
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先勾选需要批量操作对象");
        return false;
      }
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先勾选需要批量操作对象");
        return false;
      }
      //  else{
      //    this.batchAdjustBidVisible = true;
      //    this.batchApplicationVisible = true;
      //  }
      if (command == 1) {
        this.batchAdjustBidVisible = true;
      } else if (command == 2) {
        this.batchApplicationVisible = true;
      } else if (command == 3) {
        this.batchSuspendVisible = true;
      } else if (command == 4) {
        this.batchStartVisible = true;
      } else if (command == 5) {
        this.batchArchiveVisible = true;
      } else if (command == 6) {
        // 同步状态/竞价
        let data = {
          targetIds: this.keywordIdList,
          operateType: this.commandId,
        };
        BatchKeyword(data, this.pageInfo.profileId).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.data);
            // this.handleSearch();
          } else if (res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    //选择广告活动
    changeItem(val) {
      this.addInfo.screenGroupsList = this.arrList.filter((item) => {
        return item.campaignId == val.id;
      });
      this.detailsObject = {
        bid: val.bid,
        date: val.date,
        target: val.target,
        targetingType: val.targetingType,
        top: val.top,
        page: val.page,
        value: val.value,
        type: val.type,
      };
      // this.adCampaignId = val;
      this.type = 1;
      // this.adCampaignData.forEach((eq, index)=> {
      //    if(val == eq.id) {
      //      this.detailsObject = this.adCampaignData[index];
      //    }
      //  })
      // this.adCampaignData.forEach((eq, index) => {
      //   if (val == eq.id) {
      //     this.detailsObject = this.adCampaignData[index];
      //   }
      // });
      // this.arrList.forEach((item) => {
      //   if (val == item.campaignId) {
      //     this.arrLists.push({
      //       id: item.id,
      //       state: item.state,
      //       value: item.value,
      //       type: item.type,
      //       default: item.default,
      //       targetType: item.targetType,
      //     });
      //   }
      // });
    },
    handleChange(val) {
      this.adGroupId = val;
      let params = {
        groupId: val,
      };
      listExistTarget(params).then((res) => {
        this.listGet = [];
        if (res.data.code == 200) {
          const arrty = res.data.data.map((item) => {
            item.yet = 1;
            return item;
          });
          this.listGet = [...this.listGet, ...arrty];
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },
    //选择广告组
    getExistGoods(id, type, val) {
      this.dialogLoading=true
      console.log(this.rightData);
      this.asyncSearch();
      console.log(this.arrList);
      this.left_Tdata = [];
      this.targetProductList = [];
      this.arrList.forEach((item) => {
        if (id == item.id) {
          var AdGroupInfo = {
            default: item.default,
            targetType: item.targetType,
          };
          this.arrObject = AdGroupInfo;
        }
      });
      this.data = 1;
      this.adGroupId = this.adGroupId;
      this.putStatus = type;
      if (type == "商") {
        this.show_mainContent = true;
        this.getData3();
      }
      this.addInfo.expressionType = type == "自" ? "auto" : "manual";
      let params = {
        groupId: this.addInfo.adGroupId,
        profileId: this.pageInfo.profileId,
        campaignId: this.addInfo.campaignId,
      };
      // this.getTargetProduct()
      //查询已存在投放
      listExistTarget(params).then((res) => {
        if (res.data.code == 200) {
          let arr = res.data.data.map((item) => {
            item.yet = 1;
            return item;
          });
          this.listGet = arr;
        }
      });
      listByGroupId(id, this.pageInfo.profileId)
        .then((res) => {
          this.dialogLoading = false;
          if (res.data.code == 200) {
            let arr = res.data.data.map((item) => {
              item.yet = 1;
              return item;
            });
            this.rightData = arr;
          }
          this.getListNegativeTargetList();
          this.getListExistTarget();
          this.getTargetProduct(this.rightData.map(item=> item.asin).join(','))
          this.getGoodsType(this.rightData.map(item=> item.asin).join(','))
        })
        .then(() => {
          
        })
    },
    //新的 删除
    delItem(index, name) {
      this.addInfo.neTargetAddReqList.splice(index, 1);
      this.targetsBrandsList.forEach((item) => {
        if (this.addInfo.neTargetAddReqList.length == 0) {
          item.operation = 0;
        } else {
          this.addInfo.neTargetAddReqList.forEach((i) => {
            if (item.name == i.name && this.noGoodsType == "asinBrandSameAs") {
              item.operation = 0;
            }
          });
        }
      });
      this.noGoodsList.forEach((item, ind) => {
        if (item.name == name) {
          this.noGoodsList.splice(ind, 1);
        }
      });
    },
    //新的添加
    toTargetsBrandsList(row, index) {
      if (this.noGoods == "" && this.noGoodsType == "asinSameAs") {
        this.$message.warning("请输入内容");
        return;
      }
      if (this.noGoods == "" && this.noGoodsType == "asinBrandSameAs") {
        this.addInfo.neTargetAddReqList.push({
          name: row.name,
          type: this.noGoodsType,
          value: row.id,
        });
        this.noGoodsList.push({
          name: row.name,
          type: this.noGoodsType,
          value: row.id,
        });
        this.targetsBrandsList[index].operation = 1;
      } else {
        this.addNoGoods();
      }
    },
    //新的 删除全部
    delAllnoGood() {
      if (this.noGoodsList.length == 0) {
        this.$message.warning(
          "只能删除新增的，已存在的可点击归档以达到删除的目的"
        );
      } else {
        this.addInfo.neTargetAddReqList.forEach((item, index) => {
          if (item.yet != 1) {
            this.addInfo.neTargetAddReqList.splice(index, 1);
          }
        });
        this.noGoodsList = [];
      }
    },
    //新的 搜索
    getTargetsBrandsList() {
      this.getData7();
    },
    //底部已存在
    getListNegativeTargetList() {
      listNegativeTarget({ groupId: this.addInfo.adGroupId }).then((res) => {
        if (res.data.code == 200) {
          this.addInfo.neTargetAddReqList = res.data.data.map((item) => {
            item.yet = 1;
            return item;
          });
        }
      });
    },
    // 添加事件
    handleIncrease() {},
    // 底部全部删除
    handleAllDelete() {
      this.tDataList = [];
    },
    //否定商品添加
    addAdd(item, index) {
      item.operations = 1;
      if (item != undefined) {
        this.tDataList.push({ ...item, operations: 1 });
      }
      this.getData7();
      const arr = [];
      this.tDataList.forEach((i) => {
        arr.push(i.asin);
      });
      if (this.noGoods == "" && this.noGoodsType == "asinSameAs") {
        this.$message.warning("请输入内容");
        return;
      }
      if (this.noGoods == "" && this.noGoodsType == "asinBrandSameAs") {
        this.addInfo.neTargetAddReqList.push({
          name: row.name,
          type: this.noGoodsType,
          value: row.id,
        });
        this.noGoodsList.push({
          name: row.name,
          type: this.noGoodsType,
          value: row.id,
        });
        this.targetsBrandsList[index].operation = 1;
      } else {
        this.addNoGoods();
      }
    },
    addNoGoods() {
      if (!this.noGoods) {
        this.$message.warning("请填写ASIN");
        return;
      }
      const reg = /^B[0-9]{1}[0-9]{1}\w{7}$/;
      const list = this.noGoods.split(/[(\r\n)\r\n]+/);
      const arr = [];
      let num = 0;
      list.forEach((item) => {
        if (!reg.test(this.noGoods)) num++;
      });
      // if (num > 0) {
      //   this.$message.warning(`有${num}个ASIN格式有误`);
      //   return;
      // }
      list.forEach((item) => {
        const flag = this.addInfo.neTargetAddReqList.some((i) => {
          return item == i.value && i.type == "asinSameAs";
        });
        if (!flag) {
          arr.push({ name: item, value: item, type: "asinSameAs" });
        }
      });
      this.noGoodsList = [...this.noGoodsList, ...arr];
      this.noGoods = "";
      this.addInfo.neTargetAddReqList = [
        ...this.addInfo.neTargetAddReqList,
        ...arr,
      ];
    },
    //否定商品删除
    toTo(item, ind) {
      item.operations = 0;
      this.tDataList.splice(ind, 1);
      this.getData7();
    },
    // 删除操作
    handleDelete(index) {},
    // 确定新增否定商品定位
    addNeTargets() {
      this.buttonLoading = true;
      this.addNeTargetsInfo.amzAdTargetExpressionList = this.noGoodsList;
      this.addNeTargetsInfo.adGroupId = this.addInfo.adGroupId;
      this.addNeTargetsInfo.campaignId = this.addInfo.campaignId;
      addNeTargets(this.addNeTargetsInfo, this.pageInfo.profileId).then(
        (res) => {
          this.buttonLoading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getListNegativeTargetList();
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    handleSizeChange2(newSize) {
      this.formList.pageSize = newSize;
      this.getData3();
    },
    handleCurrentChange2(newCurrent) {
      this.formList.current = newCurrent;
      this.getData3();
    },
    // 添加
    handle_Add() {
      let data = {
        queryType: this.radioState,
        asins: this.textField.replace(/\n/g, ",").split(","),
      };
      BatchKeyword(data, this.pageInfo.profileId).then((res) => {
        if (res.data.code == 200) {
          res.data.data.forEach((item) => {
            this.rightData.unshift(item);
            this.textField = "";
          });
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },
    //状态改变
    changeStatus(targetId, status) {
      // 改变状态, 调用批量关键词操作
      this.targetIds = [targetId];
      if (status == "enabled") {
        this.batchInfo.operateType = 4;
      } else if (status == "paused") {
        this.batchInfo.operateType = 3;
      }
      let data = {
        targetIds: this.targetIds,
        operateType: this.batchInfo.operateType,
      };
      BatchKeyword(data, this.pageInfo.profileId).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          // this.handleSearch();
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },
    //调整竞价
    onClickBid(row, index) {
      this.$prompt("竞价", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: row.bid,
        inputPattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
        inputErrorMessage: "只能输入纯数字",
      }).then(({ value }) => {
        this.loading = true;
        modifyBid(
          { bid: value, ids: [row.targetId], type: "targets" },
          this.pageInfo.profileId
        ).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.tableData[index].bid = value;
          } else {
            this.$message.error(res.data.message);
          }
        });
      });
    },
    // tabs 栏切换事件
    handle_Click_first(tab) {
      if (tab.name == "second") {
        this.radioState = "asin";
        this.radioState_flag = true;
      } else if (tab.name == "first") {
        this.radioState_flag = false;
      }
    },
    sortTable(column) {
      if (column.order == "ascending") {
        this.pageInfo.sort = "asc";
      } else if (column.order == "descending") {
        this.pageInfo.sort = "desc";
      } else if (column.order == null) {
        this.pageInfo.sort = "";
        this.pageInfo.sortColumn = "";
        this.handleSearch();
        return;
      }
      this.pageInfo.sortColumn = column.prop;
      this.handleSearch();
    },
    find() {
      this.pageInfo.current = 1;
      this.pageInfo.sort = "";
      this.pageInfo.sortColumn = "";
      this.handleSearch();
    },
    // 切换时间选择
    changeTime(val) {
      this.pageInfo.startTime = val.value[0];
      this.pageInfo.endTime = val.value[1];
    },
    getChart(row) {
      this.adCampaignName = row.name;
      this.adGroupName = row.adGroupName;
      this.$store.commit("setAdvName", row.targetId);
      this.analysisClick();
    },
    // 经营分析
    analysisClick(val) {
      let params = {
        profileId: this.pageInfo.profileId,
        startDate: val ? val.value[0] : "",
        endDate: val ? val.value[1] : "",
        targetId: this.$store.state.advName,
      };
      listByTarget(params).then((res) => {
        if (res.data.code == 200) {
          this.ListOfData = res.data.data;
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
      this.drawer = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-star-off {
  color: grey;
}
.el-icon-star-on {
  color: #f7ba2a;
  font-size: 20px !important;
}
.words {
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  margin: 0 0px;
  .keyword {
    display: flex;
    > div {
      flex: 1;
      margin: 10px;
      padding-right: 15px;
    }
    > div:nth-of-type(1) {
      border-right: 1px solid #f0f0f0;
    }
  }
}
.storage {
  .append {
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-select {
        margin-right: 20px;
        margin-bottom: 20px;
        width: 220px;
      }
      .el-table {
        margin-top: 30px;
      }
      .el-input {
        width: 220px;
        margin-right: 20px;
      }
      .el-pagination {
        padding-top: 40px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
.show_content {
  width: 600px;
  height: 150px;
  margin-left: 80px;
  // background-color: #f7f7f9;
  padding-top: 0.5px;
  padding-left: 15px;
  margin-bottom: 15px;
}
.lable_div {
  // width: 150px;
  display: flex;
  justify-content: flex-start;
}
.activityInfor {
  margin-left: 10px;
  color: #999;
  font-size: 15px;
  cursor: pointer;
}
.class_div {
  width: 600px;
  height: 60px;
  margin-left: 80px;
  background-color: #f7f7f9;
  padding-top: 0.5px;
  padding-left: 15px;
  margin-bottom: 15px;
}
.el_collapse_bottom {
  display: flex;
  justify-content: flex-start;
}
.box-card {
  width: 600px;
}
.input-with-select {
  width: 450px !important;
}
.input-with-button {
  // background: #776ff6 !important;
  border-color: #776ff6 !important;
  margin-bottom: -7px;
}
// .el-button {
//   background: #776ff6;
//   border-color: #776ff6;
// }
.el-async_position {
  display: flex;
  justify-content: flex-end;
}
el-async_goods {
  display: flex;
  justify-content: flex-end;
}
.nav_top {
  margin-left: 15%;
  margin-bottom: 20px;
}
.el_row_top {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
}
.el_nav_middle_div {
  display: flex;
  justify-content: flex-start;
}
.el-pagination {
  padding-top: 15px;
  text-align: center;
}
.box-card_middle {
  width: 1060px;
}
.look {
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
}
.fn {
  width: 47%;
  height: 40px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 2%;
  text-align: center;
}
.lable {
  width: 30%;
  height: 38px;
  float: left;
  line-height: 40px;
  color: #666666ff;
  border: 1px solid #e5e5e5;
  border-radius: 4px 0px 0px 4px;
}
.value {
  width: 65%;
  height: 40px;
  float: left;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: left;
  padding-left: 50px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-left: none;
  color: #c0c4ccff;
  border-radius: 0px 4px 4px 0px;
}
.Box {
  width: 92%;
  display: flex;
  margin: 20px 40px;
  > div {
    overflow: auto;
    flex: 1;
    border: 1px solid #f0f0f0;
    padding: 10px;
    position: relative;
    > .all {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
.item {
  height: 85px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  .pic {
    width: 70px;
    height: 70px;
    position: relative;
    img{
      position: absolute;
      margin: 0 auto;
      top:50%;
      transform: translateY(-50%);
      
    }
  }
  .SKU {
    height: 50px;
    width: 170px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .state {
    display: inline-block;
    width: 24px;
    height: 16px;
    vertical-align: middle;
    margin-top: -4px;
  }
  .realizable {
    width: 120px;
    height: 50px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .realizables {
    width: 600px;
    height: 40px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .price {
    width: 150px;
    height: 60px;
    p {
      line-height: 60px;
    }
  }
}
.keywordClass {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 2550px;
  height: 650px;
  border-radius: 20px;
}
</style>